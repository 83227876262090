<template>
  <div class="goog_name_cps">
    <div class="goog_name_bg">
      <div class="price-now">
        <span class="special">¥</span>
        <span>{{ goodsInfo.price }}</span>
        <span class="price-old">¥{{ goodsInfo.market_price }}</span>

        <div class="goods-title">
          <h4>{{ goodsInfo.name }}</h4>
          <span>{{ goodsInfo.summary }}</span>
        </div>
      </div>
    </div>

    <div class="goog_name_bg">
      <div
        v-if="goodsInfo.coupons && goodsInfo.coupons.length !== 0"
        class="cps-coupon"
      >
        <div class="price">
          <span class="spe">¥</span>
          <span>{{ goodsInfo.coupons[0].money }}</span>
        </div>

        <div class="middle">
          <strong>优惠券</strong>
          <span class="sub">{{ goodsInfo.coupons[0].nameDesc }}</span>
          <span
            class="time"
          >有效期{{ goodsInfo.coupons[0].startTime }}到{{
            goodsInfo.coupons[0].endTime
          }}</span>
        </div>

        <span class="get-coupon" @click="toOutside()">立即领取</span>
      </div>
      <!--3-->
    </div>

    <div class="goog_name_bg">
      <div v-if="goodsInfo.brand_name" class="goods_img">
        品牌：<span>{{ goodsInfo.brand_name }}</span>
      </div>
    </div>

    <div class="goog_name_bg selcetSort">
      <span class="title">可选：</span>
      <div class="rightCon">
        <div
          v-for="item in goodsInfo.sku"
          :key="item.id"
          class="rightConItem"
          @click="toOutside"
        >
          <span>{{ item.name }}</span>
          <span>¥{{ item.price }}</span>
        </div>
      </div>
    </div>

    <div class="share_data">
      <div class="share_data_ov">
        <div class="share_data_l">
          <span> 规则：</span>
          <span class="share_data_style">返利说明</span>
        </div>
        <div class="share_data_r">
          <img
            src="../../assets/images/MoreIocn.png"
            alt=""
            @click="growCount()"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  querySimilerGoodsByOpen,
  goodsShareData
} from '@/services/goods.js'
export default {
  name: 'GoogNameXm',
  components: {
  },
  props: {
    goodsInfo: {
      // 商品详情数据
      type: Object,
      default: () => {
        return {}
      }
    },
    linkUrl: {
      type: String,
      default: ''
    }
    // dataList: {
    //   type: Object,
    //   default: {},
    // },
  },
  data() {
    return {
      recommendList: [],
      tabIndexs: 0,
      goodInfoText: {},
      allText: ''
    }
  },
  mounted() {

    // if () {
    // this.getRecommendList();
    // this.goodsShareDataFun();
    // }
  },
  methods: {
    // 处理万件格式
    million(value) {
      if (value * 1 && value * 1 > 9999) {
        return value / 10000 + '万件'
      } else {
        return value + '件'
      }
    },
    listLink(item) {
      // goodsId
      const obj = {
        product_type: 5,
        id: item.id,
        goodId: item.goodsId,
        skuId: '',
        linktype: 55
      }
      this.$store.dispatch('godetail', obj)
      this.$router.go(0)
    },
    // 获取推荐列表
    getRecommendList() {
      const body = {
        id: this.$route.query.id
      }

      querySimilerGoodsByOpen(body).then((res) => {
        if (res.code === 200) {
          this.recommendList = res.data
        }
      })
    },

    toOutside() {
      // this.getLoginLinkFun()
      window.location.href = this.linkUrl
    },

    goodsShareDataFun() {
      const params = {
        productId: this.goodsInfo.id,
        uid: localStorage.getItem('uid'),
        orderSource: 11
      }

      goodsShareData(params)
        .then((res) => {
          if (res.code === '200') {
            const {
              buyDesc,
              goodsName,
              panicCoupon,
              panicDesc,
              panicLink,
              saleDesc,
              yaoqingCode
            } = res.data.goodInfo
            this.allText =
              buyDesc +
              goodsName +
              panicCoupon +
              panicDesc +
              panicLink +
              saleDesc +
              yaoqingCode

            //  window.location.href = res.data.link;
          }
        })
        .catch((e) => {})
    },
    growCount: function() {
      this.$router.push('/growCount')
    }
  }
}
</script>
<style scoped lang="less">
.goog_name_cps {
  margin: 0 10px;

  .selcetSort {
    display: flex;
    margin-top: 10px;
    color: #333;
    padding: 15px 10px;
    background: #fff;
    font-size: 14px;
    justify-content: space-between;
  }

  .selcetSort .title {
    display: flex;
    color: #999;
  }

  .selcetSort .rightCon {
    display: flex;
    flex: 1;
    font-size: 13px;
    line-height: 22px;
    flex-flow: wrap;
  }

  .rightConItem {
    background-color: #f4f4f4;
    align-items: center;
    padding: 10px 10px;
    font-size: 12px;
    border-radius: 5px;
    margin: 0 10px 10px 0;
    word-break: break-all;
  }

  .goog_name_bg {
    background: #fff;
    margin-bottom: 10px;

    .goog_name {
      overflow: hidden;
      width: 100%;
      height: 40px;
      background: url("../../assets/images/tabbg.png") no-repeat;
      background-size: 100% 100%;
      position: relative;
      .goog_name_l {
        line-height: 40px;
        text-align: left;
        float: left;
        color: #e33f44;
        margin-left: 12px;
        font-weight: 500;
      }
      .goog_name_title {
        height: 40px;
        padding: 0 12px;
        background: #e33f44;
        border-radius: 0 20px 20px 0;
        line-height: 40px;
        font-size: 16px;
        color: #fff;
        float: left;
      }
    }
    .goods-title {
      overflow: hidden;
      h4 {
        font-size: 14px;
        color: #333;
        padding: 8px 0;
        font-weight: normal;
      }
      span {
        display: block;
        font-size: 12px;
        color: #666;
      }
    }
    .price-now {
      overflow: hidden;
      text-align: left;
      color: #ff001c;
      font-size: 21px;
      padding: 10px;
      .special {
        font-size: 14px;
      }
      .price-old {
        color: #999999;
        font-size: 12px;
        text-decoration: line-through;
        margin-left: 12px;
      }
      .price-box {
        font-size: 12px;
        border: 1px solid #e33f44;
        background: rgba(191, 55, 55, 0.3);
        border-radius: 4px;
        padding: 2px 5px;
        margin-left: 12px;
        vertical-align: 2px;
      }
      .prices_ale_num {
        color: #999999;
        font-size: 12px;
        float: right;
        line-height: 32px;
      }
    }
    .goods_img {
      font-size: 14px;
      color: #333;
      padding: 10px;
      text-align: left;
    }
    .cps-coupon {
      height: 110px;
      background: url(https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2021-06-01/20/yuelvhuikCogLCT0yU1622549817.png)
        no-repeat;
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      width: 100%;
      position: relative;

      .price {
        width: 90px;
        font-size: 30px;
        font-family: PingFang SC, PingFang SC-Semibold;
        color: #333;
        display: flex;
        flex-direction: row;
        align-items: baseline;
        justify-content: center;
        .spe {
          font-size: 13px;
        }
      }
      .middle {
        padding-left: 15px;
        display: flex;
        align-items: baseline;
        flex-direction: column;
        color: #333;
      }

      .middle strong {
        font-size: 18px;
        font-weight: normal;
        color: #333;
      }

      .middle .sub {
        font-size: 12px;
        padding: 5px 0;
        color: #333;
      }

      .middle .time {
        color: #666;
        font-size: 10px;
      }

      .get-coupon {
        position: absolute;
        right: 12px;
        top: 0;
        bottom: 0;
        font-size: 14px;
        color: #fff;
        display: flex;
        align-items: center;
        width: 40px;
      }
    }
  }
  .share_data {
    background: #fff;
    margin-top: 10px;
    width: 100%;
    padding-bottom: 1px;
    box-sizing: border-box;
    .share_data_ov {
      padding: 12px;
      box-sizing: border-box;
      overflow: hidden;
      .share_data_l {
        float: left;
        span {
          color: #666;
        }
        .share_data_style {
          color: #333;
        }
      }
      .share_data_r {
        float: right;
        img {
          width: 7px;
          height: auto;
        }
      }
    }
    .share_ov {
      padding: 0 12px;
      box-sizing: border-box;
      overflow: hidden;
      text-align: left;
      display: flex;

      line-height: 23px;
      .share_l {
        color: #666;
        display: flex;
        width: 70px;
        font-size: 14px;
      }
      .share_r {
        display: flex;
        flex: 1;
        font-size: 12px;
        padding-left: 10px;
        white-space: pre-wrap;
        word-wrap: break-word;
      }
    }
    .share_data_btn {
      width: 190px;
      margin: 20px auto 12px;
      background: #e33f44;
      height: 26px;
      line-height: 26px;
      color: #fff;
      font-size: 12px;
      border-radius: 12px;
      font-weight: 500;
    }
  }
}

.linktypeBox {
  text-align: left;

  .linktypeBg {
    background: #fff;
    padding: 10px;
    .linktypePriceBox {
      overflow: hidden;
      .linktypePrice {
        float: left;
        color: #f7263c;
        font-size: 24px;
        margin-right: 10px;
        i {
          font-size: 12px;
          font-style: normal;
        }
      }
      .linktypeOldPrice {
        float: left;
        font-size: 12px;
        margin-right: 10px;
        span {
          display: block;
        }
        .linktypeOld {
          color: #999999;
          text-decoration: line-through;
        }
        .linktypeTitle {
          font-weight: 400;

          color: #f7263c;
        }
      }
      .linktypeNum {
        float: left;
        line-height: 38px;
        font-size: 12px;
        color: #999999;
      }
    }

    .linktypeGoodName {
      font-size: 16px;
      line-height: 22px;
      font-weight: bold;
    }
  }
}

.recommendBox {
  padding: 10px;
  .recommendTitle {
    height: 19px;
    .recommendName {
      font-size: 16px;
      color: #000;
      font-weight: bold;
      margin-right: 10px;
    }
    // padding: 10px 0;
    img {
      height: 19px;
      width: auto;
      vertical-align: middle;
    }
  }
}

.recommendListBox {
  width: 100%;
  .recommended_item {
    .recommended_item_img {
      // height: 170px;
    }
    .recommended_item_box {
      margin: 0 8px;
    }
    width: 100%;
    background: #fff;
    border-radius: 9px;
    margin-bottom: 9px;

    overflow: hidden;
    img {
      height: 100px;
    }
    .tit {
      color: #191919;
      font-size: 14px;
      overflow: hidden;
      display: -webkit-box;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2; /*要显示的行数*/
      -webkit-box-orient: vertical;
      margin: 10px 0 5px 0;
      line-height: 20px;
      font-weight: bold;
      img {
        width: 16px;
        height: 14px;
        border-radius: 4px;
      }
    }
    .desc {
      width: 68px;
      height: 24px;
      background: url("../../assets/images/yhj_red.png") no-repeat;
      background-size: 100% 100%;
      text-align: center;
      line-height: 24px;
      color: #fff;

      // .desc_one {
      //   color: red;
      //   font-size: 10px;
      //   background: rgba(212, 146, 146, 0.4);
      //   display: initial;
      //   border: 1px solid red;
      //   border-radius: 4px;
      //   padding: 0 4px;
      //   box-sizing: border-box;
      // }
      // .desc_jd {
      //   width: 68px;
      //   height: 14px;
      //   background: url("../../assets/images/yhj_red.png") no-repeat;
      //   background-size: 100% 100%;
      //   font-size: 10px;
      //   color: #fff;
      //   padding: 1px 3px;
      //   box-sizing: border-box;
      //   margin-right: 12px;
      // }
    }
    .money {
      font-size: 17px;
      color: #c82519;
      //font-weight: bold;
      margin-top: 10px;
      margin-bottom: 4px;
      font-weight: bold;
      span {
        color: #c82519;
        font-size: 12px;
        margin-left: 2px;
        line-height: 23px;
      }
      em {
        font-size: 12px;
        font-style: normal;
      }
    }
  }
}
.wphGoodsBox {
  position: relative;
  text-align: left;
  padding: 10px;
  // margin-top:10px;
  color: red;
  font-size: 12px;
  .sale_num {
    background: #000;
    color: #fff;
    position: absolute;
    top: -20px;
    right: 0;
    padding: 3px 6px;
    border-radius: 3px;
    font-size: 12px;
  }
  span {
    margin-right: 5px;
  }
  i {
    font-style: normal;
  }
  .wphVipPrice {
    font-size: 18px;
  }
  .wphOldPrice {
    font-size: 12px;
    text-decoration: line-through;
    color: #666;
  }
  .SharePrice {
    text-align: center;
    padding: 3px 5px;
    color: red;
    border: 1px solid red;
    background: #ffc5c5;
    border-radius: 5px;
  }
}
.wphGoodsTitle {
  text-align: left;
  padding: 10px;
  font-weight: bold;
  line-height: 20px;
}
</style>
