<template>
  <div class="goods-detail">
    <skeleton v-if="!goodsInfo" type="detail" />
    <div v-else>
      <!-- 顶部和底部 -->
      <openApp
        :showtig="true"
        :goods-status="goodsStatus"
        :goods-info="goodsInfo"
        :flash-info="flashInfo"
        :link-url="linkUrl"
      />
      <!-- banner图片 -->
      <van-swipe
        :autoplay="3000"
        indicator-color="#ED2E2E"
        style="margin-bottom: 10px"
      >
        <van-swipe-item
          v-for="(image, index) in goodsInfo.pic_urls"
          :key="index"
        >
          <img v-lazy="image" class="banner">
        </van-swipe-item>
      </van-swipe>
      <!-- 商品价格，商品名称，商品描述  -->
      <googNamecps :goods-info="goodsInfo" :link-url="linkUrl" />
      <!-- 商品详情 -->
      <!-- <div class="goods-detail-info" v-if="goodsInfo.introduce_imgs">
        <div class="tit">商品详情</div>

        <div style="width: 100%">
          <img
            style="width: 100%"
            v-for="(item, index) in goodsInfo.introduce_imgs"
            :key="index"
            :src="item"
          />
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Swipe, Lazyload, SwipeItem } from 'vant'

Vue.use(Swipe).use(SwipeItem).use(Lazyload)
import skeleton from '@/component/skeleton/index'
import OpenApp from '@/component/GoodsDetail/goodsTopBtm'
import googNamecps from '@/component/GoodsDetail/googNamexm'

import { getXMGoodsDetail, getLoginLink } from '../../services/goods'
import { wxConfigInit } from '@/utils/wx-api'
import { changeURLArg } from '@/utils/common/utils'

export default {
  components: {
    OpenApp,
    googNamecps,
    skeleton
  },
  data() {
    return {
      flashInfo: {
        state: ''
      },
      linkUrl: '',
      goodsInfo: null, // 商品信息
      dataList: {},
      goodsStatus: 10
    }
  },
  mounted() {
    if (this.$route.query.id) {
      this.getJDGoodsDetail(this.$route.query.id)
    }
  },
  methods: {
    // 京东、淘宝、拼多多商品详情
    // async getCpsGoodsDetail(item) {
    //   if (item.brand === "pdd") {
    //     await pddAuth();
    //   }
    // },
    shareInfo() {
      const url = []
      this.goodsInfo.pic_urls.map((item) => {
        if (!item.includes('.mp4')) {
          url.push(item)
        }
      })
      const shareInfos = {
        title: this.goodsInfo.name,
        desc: this.goodsInfo.summary,
        link:
        changeURLArg('https://web.yuetao.vip/goodsDetailXm?id=' +
          this.goodsInfo.id +
          '&codeNumber=' +
          window.localStorage.getItem('recode'), 'room_id', this.$route.query.room_id || ''),
        imgUrl: url[0]
      }
      console.log(shareInfos)
      wxConfigInit(shareInfos)
    },
    getLoginLinkFun() {
      const params = {
        gid: this.goodsInfo.gid, // 1 app 2 h5 3 小程序
        mchId: this.goodsInfo.cpsmchid, // 1 app 2 h5 3 小程序
        mobile: localStorage.getItem('mobile'), // 1 app 2 h5 3 小程序 mobile
        mid: localStorage.getItem('uid'), // 用户ID
        roomId: this.$route.query.room_id || ''
      }
      getLoginLink(params)
        .then((res) => {
          if (res.code === 200) {
            this.linkUrl = res.data.link
            console.log(this.linkUrl)
            //  window.location.href = res.data.link;
          }
        })
        .catch((e) => {})
    },

    getJDGoodsDetail(id) {
      const params = {
        id: id, // 1 app 2 h5 3 小程序
        mid: localStorage.getItem('uid') // 用户ID
      }
      getXMGoodsDetail(params)
        .then((res) => {
          if (res.code === 200) {
            this.goodsInfo = res.data
            this.getLoginLinkFun()
            // 分享
            this.shareInfo()
          }
        })
        .catch((e) => {})
    }
  }
}
</script>

<style lang="less" scoped>
.goods-detail {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-bottom: 75px;
  overflow-x: hidden;
  background: #f5f7fa;
  .banner {
    width: 100%;
    height: 357px;
    background: #999;
  }
  .line {
    width: 100%;
    height: 7px;
    background: #f8f8f8;
  }
  .goods-detail-info {
    width: 100%;
    margin-top: 12px;
    background: #fff;
    .tit {
      width: 100%;
      height: 38px;
      line-height: 38px;
      text-align: center;
      font-size: 15px;
      color: #141414;
    }
    ::v-deep img {
      display: block;
      width: 100%;
    }
  }
}
</style>
